import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import _ from "lodash";
import logo from './logo.svg';
import '../App.css';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { Card, CardActions, CardContent, Button, Typography, Divider, TextField } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import BBQChicken from './BBQ Chicken.jpg';
import Orange from './Orange.jpg';
import BBQ_Chicken from './BBQ_Chicken.mp4';
import TasteTrek_Citrus from './TasteTrek_Citrus_A_Global_Exploration.mp4';

type VideoJSProps = { 
    options?: any;
    onReady?: (player: any) => void;
};
  
type VideoJSButton = any & {
    prototype: {
        constructor: any;
        handleClick(): void;
    };
};
  
interface Timestamp {
    time: number;
    maxDuration: number;
    duration: number;
    numbers: number[];
    color: string;
}  

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    videoRef: React.RefObject<HTMLDivElement>;
    videoSource: string;
}

export const UserMode = ({options, onReady}: VideoJSProps) => {
    const videoRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef<any | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [timestamps, setTimestamps] = useState<Timestamp[]>([]);
    const [currentFlow, setCurrentFlow] = useState<number[]>([]);

    const [videoData, setVideoData] = useState<{
        videoFile: File;
        timestamps: number[];
    } | null>(null);

    React.useEffect(() => {
        if (!playerRef.current && videoRef.current) {
            const videoElement = document.createElement('video');
            videoElement.classList.add('video-js');
            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options, () => {
            videojs.log('player is ready');
            onReady && onReady(player);
            });

            return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
            };
        }
    }, [options, onReady]);

    const ImageStyle: React.CSSProperties = { height: '25vh', width: '15vw', aspectRatio: '3 / 5', margin: 'auto', objectFit: 'cover' };
    const TextStyle: React.CSSProperties = {display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Jost', fontSize: '1.5vw'};
    const CardStyle: React.CSSProperties = {margin: '2vh 5vw', flex: '0 1 17vw'};
    const RowStyle: React.CSSProperties = {display: 'flex', flexWrap: 'wrap', justifyContent: 'center'};
    const ContainerStyle: React.CSSProperties = {padding: '2vh 1vw', width: '100vw', boxSizing: 'border-box'};
    const channelServiceUuid = "19B20000-E8F2-537E-4F6C-D104768A1214".toLowerCase();
    const batteryServiceUuid = "19B40000-E8F2-537E-4F6C-D104768A1214".toLowerCase();
    const channelCharacteristicUuid = "19b20001-e8f2-537e-4f6c-d104768a1214".toLowerCase();
    const batteryCharacteristicUuid = '19B40001-E8F2-537E-4F6C-D104768A1214'.toLowerCase();
    const [device, setDevice] = useState<BluetoothDevice | null>(null);
    const [flows, setFlows] = useState(_.range(8).map(() => "0"));
    const [battery, setBattery] = useState("unknown");
    const [channelCharacteristic, setChannelCharacteristic] = useState<BluetoothRemoteGATTCharacteristic>();
    const [batteryCharacteristic, setBatteryCharacteristic] = useState<BluetoothRemoteGATTCharacteristic>();
    const [connectedGattServer, setConnectedGattServer] = useState<BluetoothRemoteGATTServer>();
    const [isModalOpen, setModalOpen] = useState(false);
    const [videoSource, setVideoSource] = useState("");
    const [currentTime, setCurrentTime] = useState(0);

    const Modal: React.FC<ModalProps> = ({ isOpen, onClose, videoRef, videoSource }) => {
        if (!isOpen) {
            return null;
        }
      
        return (
            <div 
                style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.3)',
                }}
                onClick={onClose}
            >
                <div 
                    style={{
                        padding: 20,
                        background: '#fff',
                        borderRadius: '2px',
                        display: 'inline-block',
                        minHeight: 'auto',
                        margin: '1rem',
                        position: 'relative',
                        minWidth: 'auto',
                        boxShadow: '0px 10px 10px rgba(0,0,0,0.1)',
                        justifySelf: 'center',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gridRow: '2 / 3', gridColumn: '1 / 2', transition: 'all 0.5s ease-out', marginLeft: '6vw', opacity: '1' }} >
                        <div ref={videoRef} style={{ width: '800px', height: '450px', marginTop: '1vh', position: 'relative', border: '1px solid black' }}>
                            <video className='video-js vjs-big-play-centered' src={videoSource} controls autoPlay />
                        </div>
                    </div>
                </div>
            </div>
        );
    }    

    const handleClick = () => {
        navigator.bluetooth
        .requestDevice({
            filters: [
            { namePrefix: "Nano" },
            { namePrefix: "Arduino" },
            { services: [channelServiceUuid, batteryServiceUuid] },
            ]
        })
        .then((selectedDevice) => {
            setDevice(selectedDevice);
            console.log(`Discovered a device!`);
            console.log(selectedDevice);
            return selectedDevice.gatt?.connect();
        })
        .then((server) => {
            console.log(`Connected to GATT server`);
            console.log(server);
            setConnectedGattServer(server); // Save the connected server

            // Getting channel
            server!.getPrimaryService(channelServiceUuid).then((service) => {
            service.getCharacteristics().then((crs) => {
                console.log("channel characteristics");
                console.log(crs);
            });

            service.getCharacteristic(channelCharacteristicUuid).then((c) => {
                setChannelCharacteristic(c);
            });
            });

            // Getting battery
            server!.getPrimaryService(batteryServiceUuid).then((service) => {
            service.getCharacteristics().then((crs) => {
                console.log("battery characteristics");
                console.log(crs);
            });

            service.getCharacteristic(batteryCharacteristicUuid).then((c) => {
                setBatteryCharacteristic(c);
            });
            });
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const handleDisconnect = () => {
        if (connectedGattServer && connectedGattServer.connected) {
            connectedGattServer.disconnect();
            console.log(`Disconnected from GATT server`);
            setConnectedGattServer(undefined);
            setChannelCharacteristic(undefined);
            setBatteryCharacteristic(undefined);
        }
        handleClick()
    };

    const exponentialBackoff = (
        max: number, 
        delay: number, 
        toTry: () => Promise<any>, 
        success: (result: any) => void, 
        fail: () => void
    ) => {
        toTry().then(result => success(result))
        .catch(_ => {
            if (max === 0) {
                return fail();
            }
            console.log('Retrying in ' + delay + 's... (' + max + ' tries left)');
            setTimeout(function() {
                exponentialBackoff(--max, delay * 2, toTry, success, fail);
            }, delay * 1000);
        });
    }

    const time = (text: string) => {
    console.log('[' + new Date().toJSON().substr(11, 8) + '] ' + text);
    };

    const handleSetFlow = (flows: string[]) => {
        const flowsAsNumbers = flows.map((f: string) => {
            const parsed = parseInt(f);
            return isNaN(parsed) ? 0 : parsed;
        });
        console.log(`sending these flows to device: ${flowsAsNumbers}`);
        channelCharacteristic?.writeValue(new Uint8Array(flowsAsNumbers));
    };    

    const handleGetBattery = () => {
        batteryCharacteristic?.readValue().then(val => {
            console.log(`got val from battery: ${val.buffer}`)
            console.log(val)
            setBattery(`${val.getInt8(0)}`)
        })
    };

    const setFlow = (index: number, value: string) => {
        console.log(`set idx ${index} to ${value}`);
        setFlows((current) => {
            const copy = [...current];
            copy[index] = value;
            return copy;
        });
    };

    const handleHover = (targetFlows: number[]) => {
        // Initialize a copy of current flows as strings
        let currentFlows = [...flows];
    
        // Initialize a copy of current flows as numbers
        let numericFlows = currentFlows.map((flow) => parseInt(flow));
    
        // Calculate the increments per 50ms (0.5 seconds = 500ms = 50ms x 10)
        const increments = targetFlows.map((target, idx) => (target - numericFlows[idx]) / 10);
    
        // Setup interval to increment each flow
        const interval = setInterval(() => {
            // Increment each flow
            for (let i = 0; i < numericFlows.length; i++) {
                numericFlows[i] += increments[i];
            }
    
            // Update the flows
            numericFlows.forEach((flow, index) => {
                // Convert the numeric flow value back to string and update currentFlows
                currentFlows[index] = flow.toString();
                // Call setFlow with the updated string value
                setFlow(index, currentFlows[index]);
            });
    
            // Call your API here to handle set flow with currentFlows values
            handleSetFlow(currentFlows);
        }, 50);
    
        // After 0.5 seconds clear interval
        setTimeout(() => {
            clearInterval(interval);
        }, 500);
    }
    
    const handlePlay = (flows: number[]) => {
        handleHover(flows);
        setTimeout(() => {
            handleMouseLeave();
        }, 10000); // 10000 milliseconds = 10 seconds
    }      

    const handleMouseLeave = () => {
        const flows = [0,0,0,0,0,0,0,0];
        flows.forEach((flow, index) => {
            const flowAsString = flow.toString();
            setFlow(index, flowAsString);
            handleSetFlow(flows.map(f => f.toString()));
        });
    }    
  
    useEffect(() => {
        console.log("Running player initialization useEffect");
        console.log("playerRef.current:", playerRef.current); 
    
        if (!playerRef.current && videoRef.current) {
        const videoElement = videoRef.current.querySelector('video');
        console.log("videoElement:", videoElement); 
    
        if (videoElement) { 
            const player = playerRef.current = videojs(videoElement, { 
            ...options, 
            fluid: true, 
            controls: true,
            controlBar: {
                // your control bar setup
            }
            }, () => {
            videojs.log('player is ready');
            onReady && onReady(player);
            });
    
            if(player){
            player.on('timeupdate', () => {
                setCurrentTime(player.currentTime());
            });
            }
    
            return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
            };
        }
        }
    }, [options, onReady]);

    const handleCardClick = (e: any, cardInfo: any) => {
        // Prevent the event from being triggered when the play button is clicked
        if (e.target.getAttribute('name') !== 'play-button') {
            // Open the modal here
            setModalOpen(true);
    
            switch (cardInfo.name) {
                case "Barbeque Chicken":
                    setVideoSource(BBQ_Chicken);
                    break;
                case "Orange":
                    setVideoSource(TasteTrek_Citrus);
                    break;
                default:
                    setVideoSource("");  // set a default value or an error message
            }
            
            console.log('Card clicked:', cardInfo);
        }
    };       

    return (
        <>
            <Divider orientation='horizontal' sx={{ width: '0px', height: '5rem' }} />
            <div className="App">
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1%' }}>
                        <button
                            onClick={handleClick}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '24px', 
                                backgroundColor: 'lightblue', 
                                color: 'darkblue', 
                                marginRight: '10px', 
                                padding: '10px', 
                                border: 'none', 
                                borderRadius: '5px'
                            }}
                        >
                            <BluetoothIcon style={{ fontSize: '24px', color: 'darkblue', marginRight: '8px' }} />
                            Connect
                        </button>
                        <button
                            onClick={handleDisconnect}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '24px', 
                                backgroundColor: 'lightcoral', 
                                color: 'darkred', 
                                padding: '10px', 
                                border: 'none', 
                                borderRadius: '5px'
                            }}
                        >
                        <BluetoothDisabledIcon style={{ fontSize: '24px', color: 'darkred', marginRight: '8px' }} />
                        Disconnect
                        </button>
                    </div>
    
                    <div>
                        <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                        >
                        <div
                            style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: (channelCharacteristic && batteryCharacteristic) ? 'green' : 'red',
                            marginRight: '10px',
                            }}
                        />
                        {channelCharacteristic && batteryCharacteristic ? 
                            <p>Device Connected</p> : 
                            <p>Device Disconnected</p>
                        }
                        </div>
                    </div>
    
                    <div>
                        <b>flows</b>
                        {flows.map((current, idx) => (
                        <input
                            key={idx}
                            type='number'
                            style={{ width: "50px" }}
                            value={current}
                            min={11}
                            max={20}
                            onChange={(e) => setFlow(idx, e.target.value)}
                        ></input>
                        ))}
                        <button onClick={() => handleSetFlow(flows)}>send flows</button>
                    </div>
    
                    <div>
                        <b>battery %: {battery}</b>
                        <button onClick={handleGetBattery}>get battery</button>
                    </div>
                </div>
            </div>
            <div id='container' style={ContainerStyle}>
                <div id='row1' style={RowStyle}>
                    <Card 
                        variant="outlined" 
                        style={{...CardStyle, backgroundColor: '#ffb733'}}
                        onClick={(e) => handleCardClick(e, { name: 'Orange', description: 'Juicy, Tangy, Energizing' })} 
                    >
                        <CardContent>
                            <div id='nameOrange' style={{...TextStyle, fontSize: '1.75vw', marginBottom: '1vh'}} >
                                Orange
                            </div>
                            <img src={Orange} 
                                style={ImageStyle}
                                alt="Orange"
                                onMouseEnter={() => handleHover([50,0,0,0,0,0,0,0])}
                                onMouseLeave={handleMouseLeave}></img>
                            <div id='descriptionOrange' style={TextStyle} >Juicy, Tangy, Energizing</div>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <PlayCircleFilledIcon 
                                name="play-button" 
                                style={{ color: 'darkblue', fontSize: 40 }} 
                                onClick={() => handlePlay([50,0,0,0,0,0,0,0])}
                            />
                        </CardActions>
                    </Card>
                    <Card 
                        variant="outlined" 
                        style={{...CardStyle, backgroundColor: '#a6784a'}}
                        onClick={(e) => handleCardClick(e, { name: 'Barbeque Chicken', description: 'Savory, Tender, Smoky' })}
                    >
                        <CardContent>
                            <div id='nameBBQChicken' style={{...TextStyle, fontSize: '1.75vw', marginBottom: '1vh'}} >
                                Barbeque Chicken
                            </div>
                            <img src={BBQChicken} 
                                style={ImageStyle} 
                                alt="BBQ Chicken"
                                onMouseEnter={() => handleHover([0,50,0,0,0,0,0,0])}
                                onMouseLeave={handleMouseLeave}></img>
                            <div id='descriptionBBQChicken' style={TextStyle} >Savory, Tender, Smoky</div>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <PlayCircleFilledIcon 
                                name="play-button" 
                                style={{ color: 'darkblue', fontSize: 40 }} 
                                onClick={() => handlePlay([0,50,0,0,0,0,0,0])}
                            />
                        </CardActions>
                    </Card>
                </div>
            </div>
            <Modal 
                isOpen={isModalOpen} 
                onClose={() => setModalOpen(false)}
                videoRef={videoRef}
                videoSource="BBQ_Chicken.mp4"
            />
        </>
    );
    
};

export default UserMode;