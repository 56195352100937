import React, { useRef, useState, ChangeEvent, useEffect, forwardRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import logo from './logo.svg';
import '../App.css';
import _ from "lodash";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Card, CardActions, CardContent, Button, Typography, Divider, TextField } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import Switch from '@material-ui/core/Switch';
import { any } from 'video.js/dist/types/utils/events';
import BBQChicken from './BBQ Chicken.jpg';
import Orange from './Orange.jpg';
import BBQ_Chicken from './BBQ_Chicken.mp4';
import TasteTrek_Citrus from './TasteTrek_Citrus_A_Global_Exploration.mp4';

type VideoJSProps = { 
  options?: any;
  onReady?: (player: any) => void;
};

type VideoJSButton = any & {
  prototype: {
    constructor: any;
    handleClick(): void;
  };
};

interface Timestamp {
  time: number;
  maxDuration: number;
  duration: number;
  numbers: number[];
  color: string;
}

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    videoRef: React.RefObject<HTMLVideoElement>;
    videoSource: string;
}

export const AdminMode = ({options, onReady}: VideoJSProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<any | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [timestamps, setTimestamps] = useState<Timestamp[]>([]);
  const [currentFlow, setCurrentFlow] = useState<number[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [videoAdded, setVideoAdded] = useState(false); // new state
  const [videoData, setVideoData] = useState<string | null>(null);
  const [videoName, setVideoName] = useState(''); // new state for video name
  const Forward10IconDataURL = `url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBQRC4gTWFkZSBieSB0aGVmb3JnZXNtaXRoOiBodHRwczovL2ljb25zLnRoZWZvcmdlc21pdGguY29tIC0tPgo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjZmZmZmZmIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMjMuOTMsNDEuNDFWMjNhLjA5LjA5LDAsMCwwLS4xNi0uMDdzLTIuNTgsMy42OS00LjE3LDQuNzgiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgogICAgPHJlY3QgeD0iMjkuMTkiIHk9IjIyLjUyIiB3aWR0aD0iMTEuNDEiIGhlaWdodD0iMTguODkiIHJ4PSI1LjciLz4KICAgIDxwb2x5bGluZSBwb2ludHM9IjU0LjQzIDE1LjQxIDUxLjgzIDI0LjA1IDQzLjE5IDIxLjQ0IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KICA8cGF0aCBkPSJNNTEuODYsMjMuOTRhMjEuOTEsMjEuOTEsMCwxLDAsLjkxLDEzLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+")`;
  const Replay10IconDataURL = `url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBQRC4gTWFkZSBieSB0aGVmb3JnZXNtaXRoOiBodHRwczovL2ljb25zLnRoZWZvcmdlc21pdGguY29tIC0tPgo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjZmZmZmZmIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMjMuOTMsNDEuNDFWMjNhLjA5LjA5LDAsMCwwLS4xNi0uMDdzLTIuNTgsMy42OS00LjE3LDQuNzgiICB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0LCAwKSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CiAgICA8cmVjdCB4PSIyOS4xOSIgeT0iMjIuNTIiIHdpZHRoPSIxMS40MSIgaGVpZ2h0PSIxOC44OSIgcng9IjUuNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCwgMCkiLz4KICAgIDxwb2x5bGluZSBwb2ludHM9IjU0LjQzIDE1LjQxIDUxLjgzIDI0LjA1IDQzLjE5IDIxLjQ0IiBzdHJva2UtbGluZWNhcD0icm91bmQiICB0cmFuc2Zvcm09InNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTYzLjUsIDApIi8+CiAgPHBhdGggZD0iTTUxLjg2LDIzLjk0YTIxLjkxLDIxLjkxLDAsMSwwLC45MSwxMy4yNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiAgdHJhbnNmb3JtPSJzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC02NCwgMCkiLz4KPC9zdmc+Cg==")`;
  const ForwardButton = videojs.getComponent('Button');
  const Button = videojs.getComponent('Button');
  const [device, setDevice] = useState<BluetoothDevice | null>(null);
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [aromaDevice, setAromaDevice] = useState('Aroma Play'); // Set default value to 'Aroma Play'
  const [numProducts, setNumProducts] = useState(8); // Set default value to 8
  const ImageStyle: React.CSSProperties = { height: '25vh', width: '15vw', aspectRatio: '3 / 5', margin: 'auto', objectFit: 'cover' };
  const TextStyle: React.CSSProperties = {display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Jost', fontSize: '1.5vw'};
  const CardStyle: React.CSSProperties = {margin: '5vw', flex: '0 1 17vw'};
  const RowStyle: React.CSSProperties = {display: 'flex', flexWrap: 'wrap', justifyContent: 'center'};
  const ContainerStyle: React.CSSProperties = {padding: '1vw', width: '100vw', boxSizing: 'border-box'};
  const channelServiceUuid = "19B20000-E8F2-537E-4F6C-D104768A1214".toLowerCase();
  const batteryServiceUuid = "19B40000-E8F2-537E-4F6C-D104768A1214".toLowerCase();
  const channelCharacteristicUuid = "19b20001-e8f2-537e-4f6c-d104768a1214".toLowerCase();
  const batteryCharacteristicUuid = '19B40001-E8F2-537E-4F6C-D104768A1214'.toLowerCase();
  const [flows, setFlows] = useState<number[]>(Array(8).fill(0));
  const [targetFlows, setTargetFlows] = useState([0,0,0,0,0,0,0,0]);
  const [currentTime, setCurrentTime] = useState(0);
  const [battery, setBattery] = useState("unknown");
  const [channelCharacteristic, setChannelCharacteristic] = useState<BluetoothRemoteGATTCharacteristic>();
  const [batteryCharacteristic, setBatteryCharacteristic] = useState<BluetoothRemoteGATTCharacteristic>();
  const [connectedGattServer, setConnectedGattServer] = useState<BluetoothRemoteGATTServer>();
  const [targetFlow, setTargetFlow] = useState(0);
  const [currentTimestamp, setCurrentTimestamp] = useState<Timestamp | null>(null);
  const [targetTimestamp, setTargetTimestamp] = useState<Timestamp | null>(null);
  const citrusTrekTimestamps = [{
    time: 20,  // converted 0:20 to seconds
    maxDuration: 224,  // converted 3:44 to seconds
    duration: 5,
    numbers: [80, 0, 0, 0, 0, 0, 0, 0],
    color: ""  // assign random color hex code here
}, 
{
    time: 60,
    maxDuration: 184,
    duration: 7,
    numbers: [0, 80, 0, 0, 0, 0, 0, 0],
    color: ""  // assign random color hex code here
},
{
    time: 88,
    maxDuration: 156,
    duration: 5,
    numbers: [0, 0, 80, 0, 0, 0, 0, 0],
    color: ""  // assign random color hex code here
},
{
    time: 123,
    maxDuration: 121,
    duration: 5,
    numbers: [0, 0, 0, 80, 0, 0, 0, 0],
    color: ""  // assign random color hex code here
},
{
    time: 235,
    maxDuration: 9,
    duration: 5,
    numbers: [0, 0, 0, 0, 80, 0, 0, 0],
    color: ""  // assign random color hex code here
}];
  const BBQChickenTimestamps = [{
    time: 15,  // converted 0:15 to seconds
    maxDuration: 79,  // converted 1:19 to seconds
    duration: 4,
    numbers: [80, 0, 0, 0, 0, 0, 0, 0],
    color: ""  // assign random color hex code here
}, 
{
    time: 24,
    maxDuration: 70,
    duration: 7,
    numbers: [0, 80, 0, 0, 0, 0, 0, 0],
    color: ""  // assign random color hex code here
},
{
    time: 50,
    maxDuration: 44,
    duration: 5,
    numbers: [0, 0, 80, 0, 0, 0, 0, 0],
    color: ""  // assign random color hex code here
},
{
    time: 75,
    maxDuration: 19,
    duration: 7,
    numbers: [0, 0, 0, 80, 0, 0, 0, 0],
    color: ""  // assign random color hex code here
}];

  console.log(timestamps)
  
  const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  
  const assignRandomColorsToTimestamps = (timestamps: any) => {
    return timestamps.map((timestamp: any) => {
      return {...timestamp, color: getRandomColor() };
    });
  };
  

  const handleBluetoothClick = () => {
    navigator.bluetooth
      .requestDevice({
        filters: [
          { namePrefix: "Nano" },
          { namePrefix: "Arduino" },
          { services: [channelServiceUuid, batteryServiceUuid] },
        ]
      })
      .then((selectedDevice) => {
        setDevice(selectedDevice);
        selectedDevice.ongattserverdisconnected = function (event) {
          console.log("Device disconnected", event);
        };
        console.log(`Discovered a device!`);
        console.log(selectedDevice);
        console.log('GATT Server:', selectedDevice.gatt);
        return selectedDevice.gatt?.connect().catch(error => {
          console.error('Error connecting to GATT server:', error);
          throw error;  // Rethrow so the error is still caught later in the chain
        });
      })
      .then((server) => {
        console.log(`Connected to GATT server`);
        console.log(server);
        setConnectedGattServer(server); // Save the connected server
        setDeviceConnected(true); // Update the deviceConnected state
        return server?.getPrimaryService(channelServiceUuid);
      })
      .then((service) => {
        console.log('Channel Service:', service);
        return service?.getCharacteristic(channelCharacteristicUuid);
      })
      .then((characteristic) => {
        console.log('Channel Characteristic:', characteristic);
        setChannelCharacteristic(characteristic);
        return characteristic?.service?.device?.gatt?.getPrimaryService(batteryServiceUuid);
      })
      .then((service) => {
        console.log('Battery Service:', service);
        return service?.getCharacteristic(batteryCharacteristicUuid);
      })
      .then((characteristic) => {
        console.log('Battery Characteristic:', characteristic);
        setBatteryCharacteristic(characteristic);
      })
      .catch((error) => {
        console.error(error);
      });    
  
    console.log("Channel Characteristic:", channelCharacteristic);
    console.log("Battery Characteristic:", batteryCharacteristic);
  };
  
  const handleDisconnect = () => {
    console.log("handleDisconnect called");
    if (connectedGattServer && connectedGattServer.connected) {
      connectedGattServer.disconnect();
      console.log(`Disconnected from GATT server`);
      setConnectedGattServer(undefined);
      setChannelCharacteristic(undefined);
      setBatteryCharacteristic(undefined);
      setDeviceConnected(false);
    }
    handleBluetoothClick()
  };

  const exponentialBackoff = (
      max: number, 
      delay: number, 
      toTry: () => Promise<any>, 
      success: (result: any) => void, 
      fail: () => void
  ) => {
      toTry().then(result => success(result))
      .catch(_ => {
          if (max === 0) {
              return fail();
          }
          console.log('Retrying in ' + delay + 's... (' + max + ' tries left)');
          setTimeout(function() {
              exponentialBackoff(--max, delay * 2, toTry, success, fail);
          }, delay * 1000);
      });
  }

  const time = (text: string) => {
    console.log('[' + new Date().toJSON().substr(11, 8) + '] ' + text);
  };

  const handleSetFlow = (flows: number[]): void => {
    const flowsAsNumbers = flows.map((f: number) => {
      const parsed = f;
      return isNaN(parsed) ? 0 : parsed;
    });
    console.log(`sending these flows to device: ${flowsAsNumbers}`);
    channelCharacteristic?.writeValue(new Uint8Array(flowsAsNumbers));
  };
  
  const updateFlows = (target: any) => {
    let currentFlows = [...flows]; // copy current flows to a new array
    const intervalId = setInterval(() => {
      // Check if all flows have reached the target
      if (currentFlows.every((flow) => flow >= target)) {
        clearInterval(intervalId); // If so, stop the interval
      } else {
        // If not, increment each flow that hasn't reached the target
        currentFlows = currentFlows.map((flow) => (flow < target ? flow + 1 : flow));
        setFlows(currentFlows); // Update state
      }
    }, 1000); // Update every second
  };

  useEffect(() => {
    if (currentTimestamp === targetTimestamp) {
      updateFlows(targetFlow);
    }
  }, [currentTimestamp]);

  useEffect(() => {
    handleSetFlow(flows);
  }, [flows]);

  useEffect(() => {
    const handleFullscreenChange = () => updateProgressCircles();
  
    if (!playerRef.current) return;
  
    // Listen to fullscreen changes and update progress circles
    playerRef.current.on('fullscreenchange', handleFullscreenChange);
  
    updateProgressCircles();
  
    return () => {
      // Clean up listener on unmount
      if (playerRef.current) {
        playerRef.current.off('fullscreenchange', handleFullscreenChange);
      }
    };
  }, [timestamps]);

  const increaseFlow = (targetFlows: number[], time: number = 500) => {
    let current = 0;
    const intervalTime = 50; // 50 ms for smoother transition
    let steps = targetFlows.map((flow: number, index: number) => (flow - currentFlow[index]) / (time / intervalTime));
    let intervalId = setInterval(() => {
      if (current >= time) {
        clearInterval(intervalId);
        decreaseFlow(targetFlows, time); // Start decreasing
      } else {
        setCurrentFlow(prevFlows => prevFlows.map((prevFlow: number, index: number) => prevFlow + steps[index]));
        handleSetFlow(currentFlow);
        current += intervalTime;
      }
    }, intervalTime);
  };
  
  const decreaseFlow = (targetFlows: number[], time: number = 500) => {
    let current = 0;
    const intervalTime = 50; // 50 ms for smoother transition
    let steps = targetFlows.map((flow: number, index: number) => flow / (time / intervalTime));
    let intervalId = setInterval(() => {
      if (current >= time) {
        clearInterval(intervalId);
      } else {
        setCurrentFlow(prevFlows => prevFlows.map((prevFlow: number, index: number) => prevFlow - steps[index] >= 0 ? prevFlow - steps[index] : 0));
        handleSetFlow(currentFlow);
        current += intervalTime;
      }
    }, intervalTime);
  };
  
  useEffect(() => {
    const matchingTimestamp = timestamps.find(t => currentTime >= t.time && currentTime <= t.time + t.duration);
    if(matchingTimestamp) {
      increaseFlow(matchingTimestamp.numbers, 500); // Adjust to increase first
    } else {
      decreaseFlow(currentFlow, 500); // If no matching timestamp, decrease to 0
    }
  }, [currentTime]);
   
  
  const adjustFlow = (targetFlows: number[], time: number = 500) => {
    let current = 0;
    let steps = targetFlows.map((flow: number, index: number) => (flow - currentFlow[index]) / (time / 100));
    let intervalId = setInterval(() => {
      if (current >= time) {
        clearInterval(intervalId);
      } else {
        setCurrentFlow(prevFlows => {
          console.log(prevFlows); // check the previous state
          return prevFlows.map((prevFlow: number, index: number) => prevFlow + steps[index]);
        });
        console.log(currentFlow); // check the current state (might not reflect changes immediately due to async nature of set state)
        handleSetFlow(currentFlow.map((prevFlow: number, index: number) => prevFlow + steps[index]));
        current += 100;
      }
    }, 100);
  };

  useEffect(() => {
    console.log(currentFlow); // logs the updated state whenever it changes
  }, [currentFlow]);

  const handleGetBattery = () => {
      batteryCharacteristic?.readValue().then(val => {
          console.log(`got val from battery: ${val.buffer}`)
          console.log(val)
          setBattery(`${val.getInt8(0)}`)
      })
  };

  
  const Modal: React.FC<ModalProps> = ({ isOpen, onClose, videoRef, videoSource }) => {
    const [currentTimestamp, setCurrentTimestamp] = React.useState<number | null>(null);
  
    const handleVideoTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
      setCurrentTimestamp(event.currentTarget.currentTime);
      // Add any other logic you need based on the current time
    };
  
    if (!isOpen) {
      return null;
    }
  
    const modifiedOnClose = () => {
      setCurrentTimestamp(null);  // Resetting the timestamp state
      onClose();  // Calling the original onClose function
    }
  
    return (
      <div 
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.7)', // semi-transparent black
          zIndex: 9999, // ensure the modal is above all other content
        }}
        onClick={modifiedOnClose}  // Close the modal when the background is clicked
      >
        <div 
          style={{
            padding: 20,
            background: '#fff',
            height: '70%',
            width: '70%',
            position: 'relative',
            boxSizing: 'border-box',
          }}
          onClick={(e) => e.stopPropagation()}  // Prevent the click event from propagating to the background
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', opacity: '1' }} >
            <div style={{ width: '100%', height: '80%', paddingBottom: '56.25%', position: 'relative', boxSizing: 'border-box' }}>
              <video 
                style={{ width: '100%', height: '81%', position: 'absolute', top: '0', left: '0', objectFit: 'contain'}} 
                className='video-js vjs-big-play-centered' 
                src={videoSource} 
                controls 
                autoPlay 
                loop 
                muted 
                onTimeUpdate={handleVideoTimeUpdate}  // Added this line here
                ref={videoRef}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const setFlow = (index: number, value: number) => {
      console.log(`set idx ${index} to ${value}`);
      setFlows((current) => {
          const copy = [...current];
          copy[index] = value;
          return copy;
      });
  };

  // Custom forward button
  class SkipForwardButton extends (ForwardButton as any as { new(...args: any[]): VideoJSButton; }) {
    constructor(player: any, options: any) {
      super(player, options);
      this.addClass('vjs-skip-forward-button');
      this.el().style.backgroundSize = 'calc(50%)';
      this.el().style.backgroundPosition = 'center';
      this.el().style.backgroundRepeat = 'no-repeat';
      this.el().style.color = 'white';
      this.el().style.cursor = 'pointer';
      this.el().style.backgroundImage = Forward10IconDataURL;
      this.el().title = 'Skip Forward 10s';
    }

    handleClick() {
      const player = this.player();
      player.currentTime(player.currentTime() + 10); // skip forward 10 seconds
    }
  }

  // Custom backward button
  class SkipBackwardButton extends (ForwardButton as any as { new(...args: any[]): VideoJSButton; }) {
    constructor(player: any, options: any) {
      super(player, options);
      this.addClass('vjs-skip-backward-button');
      this.el().style.backgroundSize = 'calc(50%)';
      this.el().style.backgroundPosition = 'center';
      this.el().style.backgroundRepeat = 'no-repeat';
      this.el().style.color = 'white';
      this.el().style.cursor = 'pointer';
      this.el().style.backgroundImage = Replay10IconDataURL;
      this.el().title = 'Skip Backward 10s';
    }

    handleClick() {
      const player = this.player();
      player.currentTime(player.currentTime() - 10); // skip back 10 seconds
    }
  }

  // Custom Add TimeStamp button
  class AddTimeStampButton extends ( Button as any as { new(...args: any[]): VideoJSButton; }) {
    constructor(player:any, options:any) {
      super(player, options);
      this.on('click', () => {
        options.handleClick();
      });
      this.el().style.backgroundSize = 'calc(50%)';
      this.el().style.backgroundPosition = 'center';
      this.el().style.backgroundRepeat = 'no-repeat';
      this.el().style.color = 'white';
      this.el().title = 'Add Timestamp';
      this.el().style.cursor = 'pointer';
    }
    
    createEl() {
      return videojs.dom.createEl('button', {
        className: 'vjs-icon-plus vjs-control vjs-button',
        innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>`,
      });
    }
  }

  const handleAddTimestampClick = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.currentTime();
  
      // Pause the video
      playerRef.current.pause();
        
      const newTimestamp: Timestamp = {
        time: currentTime,
        maxDuration: playerRef.current.duration() - currentTime,
        duration: 0,
        numbers: Array(8).fill(0),
        color: getRandomColor(),
      };
  
      // Add the new timestamp and sort the array
      setTimestamps((prevTimestamps) => {
        // Check if currentTime already exists in prevTimestamps
        if (prevTimestamps.some(timestamp => Math.abs(timestamp.time - currentTime) < 0.01)) {
          alert("Timestamp already exists for this time!");
          return prevTimestamps;
        }
  
        return [...prevTimestamps, newTimestamp].sort((a, b) => a.time - b.time);        
      });
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file) {
        console.log(file); // logs the file details
        const videoURL = URL.createObjectURL(file);
        if (playerRef.current) {
          playerRef.current.src({ type: file.type, src: videoURL });
          playerRef.current.load();
        }
      }
    }
  };  

  const hexToRgb = (hex: any) => { // This function converts HEX color to RGB
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
    
    videojs.registerComponent('SkipForwardButton', SkipForwardButton as any);   // Register the Forward button
    videojs.registerComponent('SkipBackwardButton', SkipBackwardButton as any); // Register the Backward button
    videojs.registerComponent('AddTimeStampButton', AddTimeStampButton as any); // Register the AddTimeStamp button

    const currentTimestampData = timestamps.find(({ time, duration, maxDuration }) =>
      currentTime >= time && currentTime <= time + maxDuration
    );

  useEffect(() => {
    // Assuming timestamps array is sorted in ascending order by time
    const currentTimestamp = timestamps.find(({ time, duration }) =>
      currentTime >= time && currentTime < time + duration
    );
  
    if (currentTimestamp) {
      if (currentTimestamp.time !== targetTimestamp?.time) {
        setTargetFlows(currentTimestamp.numbers);
        setCurrentTimestamp(currentTimestamp);
      }
    } else {
      setTargetFlows([0, 0, 0, 0, 0, 0, 0, 0]);
      setCurrentTimestamp(null);
    }
  }, [currentTime]); 

  useEffect(() => {
    const currentTimestampData = timestamps.find(({time, duration, maxDuration}) =>
      currentTime >= time + duration && currentTime <= time + maxDuration
    );
  
    if(currentTimestampData){
      setTargetFlows([0,0,0,0,0,0,0,0]);
    }
  }, [currentTime]);

  useEffect(() => {
    if (currentTimestamp === targetTimestamp) {
      updateFlows(targetFlow);
    }
  }, [currentTimestamp]);

  useEffect(() => {
    if(currentTimestampData){
      setTargetFlows([0,0,0,0,0,0,0,0]);
    }  
    
    const interval = setInterval(() => {
      setFlows(targetFlows);
    }, 500); // Runs every 0.5 seconds
  
    return () => {
      clearInterval(interval);
    };
  }, [targetFlows]);
  
  const updateProgressCircles = () => {
    if (!playerRef.current) return;
  
    const controlBar = playerRef.current.getChild('controlBar');
    const progressControl = controlBar.getChild('progressControl');
  
    if (!progressControl) {
      return;
    }
  
    const progressBar = progressControl.el();
  
    // Remove existing circles
    const existingCircles = progressBar.querySelectorAll('.vjs-timestamp-circle');
    existingCircles.forEach((circle: any) => circle.parentNode?.removeChild(circle));
  
    // If there are any timestamps, add new circles
    if (timestamps.length > 0) {
      const videoDuration = playerRef.current.duration();
      const progressBarElement = progressControl.el().querySelector('.vjs-load-progress');
      const progressBarWidth = progressBarElement?.offsetWidth || 0;
  
      // Create a colored circle for each timestamp
      timestamps.forEach((timestamp) => {
        const circle = createColoredCircle(timestamp, videoDuration, progressBarWidth);
        progressBar.appendChild(circle);
      });
    }
  };
  
  const createColoredCircle = (timestamp: any, videoDuration: number, progressBarWidth: number) => {
    const colorRGB = hexToRgb(timestamp.color);
    const circle = document.createElement('div');
  
    circle.classList.add('vjs-timestamp-circle');
    circle.style.position = 'absolute';
    circle.style.bottom = '0';
    circle.style.height = '100%';
  
    // Calculate the width as a percentage of the video's total duration
    const widthPercentage = (timestamp.duration / videoDuration) * 100;
    circle.style.width = `${widthPercentage}%`;
  
    circle.style.borderRadius = '0%';
    circle.style.left = `${((timestamp.time / videoDuration) * progressBarWidth) + 10}px`;
  
    circle.style.backgroundColor = colorRGB 
      ? `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.5)`
      : timestamp.color; // fallback to original color if conversion failed
  
    return circle;
  };
  
  useEffect(() => {
    console.log("Running player initialization useEffect");
    console.log("playerRef.current:", playerRef.current); 
  
    if (!playerRef.current && videoRef.current) {
      const videoElement = videoRef.current.querySelector('video');
      console.log("videoElement:", videoElement); 
  
      if (videoElement) { 
        const player = playerRef.current = videojs(videoElement, { 
          ...options, 
          fluid: true, 
          controls: true,
          controlBar: {
            // your control bar setup
          }
        }, () => {
          videojs.log('player is ready');
          onReady && onReady(player);
        });
  
        if(player){
          player.on('timeupdate', () => {
            setCurrentTime(player.currentTime());
          });
        }        
  
        // Add the button to the control bar
        player.getChild('controlBar')?.addChild('AddTimeStampButton', {
          text: 'Add Timestamp',
          handleClick: handleAddTimestampClick,
        }, 12);
  
        return () => {
          if (player && !player.isDisposed()) {
            player.dispose();
            playerRef.current = null;
          }
        };
      }
    }
  }, [options, onReady]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };  

  const sendData = () => {
    let text = window.document.getElementById("text")?.innerText
    $.getJSON( '127.0.0.1:9000/_add_text', {
      a: text,
    }, function(data: any) {
      console.log(data);
    });
    return false;
  };

  let interval: any;  // Declare interval variable outside to clear it as needed

const transitionFlows = (startFlows: number[], targetFlows: number[]) => {
    let currentFlows = [...startFlows];

    // Calculate the increments per 50ms (0.5 seconds = 500ms = 50ms x 10)
    const increments = targetFlows.map((target, idx) => (target - currentFlows[idx]) / 10);

    // Setup interval to increment each flow
    interval = setInterval(() => {
        // Increment each flow
        for (let i = 0; i < currentFlows.length; i++) {
            currentFlows[i] += increments[i];
        }

        // Update the flows
        currentFlows.forEach((flow, index) => {
            // Call setFlow with the updated value
            setFlow(index, flow);
        });

        // Call your API here to handle set flow with currentFlows values
        handleSetFlow(currentFlows);
    }, 50);

    // After 0.5 seconds clear interval
    setTimeout(() => {
        clearInterval(interval);
    }, 500);
};

const handleHover = (targetFlows: number[], isEntering: boolean = true) => {
  // Initialize a copy of current flows as strings
  let currentFlows = [...flows];

  // If mouse is leaving, set targetFlows to [0, 0, 0, ...]
  if (!isEntering) {
    targetFlows = Array(flows.length).fill(0);
  }

  // Initialize a copy of current flows as numbers
  let numericFlows = currentFlows.map((flow) => flow);

  // Calculate the increments per 50ms (0.5 seconds = 500ms = 50ms x 10)
  const increments = targetFlows.map((target, idx) => (target - numericFlows[idx]) / 10);

  // Setup interval to increment each flow
  const interval = setInterval(() => {
    // Increment each flow
    for (let i = 0; i < numericFlows.length; i++) {
      numericFlows[i] += increments[i];
    }

    // Update the flows
    numericFlows.forEach((flow, index) => {
      currentFlows[index] = flow;
      setFlow(index, currentFlows[index]);
    });

    // Call your API here to handle set flow with currentFlows values
    handleSetFlow(currentFlows);
  }, 50);

  // After 0.5 seconds clear interval
  setTimeout(() => {
    clearInterval(interval);
  }, 500);
}

const handleHoverOut = () => {
    clearInterval(interval);  // Clear any existing interval to avoid overlaps
    const zeroFlows = Array(flows.length).fill(0);
    transitionFlows(flows, zeroFlows);
};

  
  const handlePlay = (flows: number[]) => {
      handleHover(flows);
      setTimeout(() => {
          handleMouseLeave();
      }, 10000); // 10000 milliseconds = 10 seconds
  }      

  const handleVideoPlay = (e: any, videoSource: any) => {
    e.stopPropagation();  // Prevent the event from propagating to parent components
  
    setVideoSource(videoSource);
    setModalOpen(true);
  };  

  const handleMouseLeave = () => {
      const flows = [0,0,0,0,0,0,0,0];
      flows.forEach((flow, index) => {
          setFlow(index, flow);
          handleSetFlow(flows.map(f => f));
      });
  }    
  
  const getFlowsForVideo = (videoSrc: string): number[] => {
    if (videoSrc === BBQ_Chicken) {
      return BBQChickenTimestamps.map(timestamp => timestamp.numbers[0]);  // assuming the first number in the numbers array is the flow value
    } else if (videoSrc === TasteTrek_Citrus) {
      return citrusTrekTimestamps.map(timestamp => timestamp.numbers[0]);
    }
    return [];
  };
  
  const sendFlowsForBothVideos = () => {
    const BBQChickenFlows = getFlowsForVideo(BBQ_Chicken);
    const citrusTrekFlows = getFlowsForVideo(TasteTrek_Citrus);
    
    handleSetFlow([...BBQChickenFlows, ...citrusTrekFlows]);
  };
  
  const handleCardClick = (e: any, cardInfo: any) => {
      setModalOpen(true);
    
      let selectedTimestamps = [];
      switch (cardInfo.name) {
        case "Barbeque Chicken":
          selectedTimestamps = assignRandomColorsToTimestamps(BBQChickenTimestamps);
          setVideoSource(BBQ_Chicken);
          break;
        case "Orange":
          selectedTimestamps = assignRandomColorsToTimestamps(citrusTrekTimestamps);
          setVideoSource(TasteTrek_Citrus);
          break;
        default:
          setVideoSource("");
      }
      setTimestamps(selectedTimestamps);
  
      // Call the sendFlows function here
      sendFlowsForBothVideos();
  
      console.log('Card clicked:', cardInfo);
  };  

useEffect(() => {
  if(currentTimestamp) {
    // Do any additional logic here, e.g., update the UI, flows, etc.
    console.log("Current timestamp changed:", currentTimestamp);
  }
}, [currentTimestamp]);
  
    return (
      <>
        <div className="App" style={{ display: 'grid', gridTemplateRows: '1fr', gridTemplateColumns: '1fr', gap: '1rem', height: '100vh', alignItems: 'center', justifyContent: 'center', transition: 'all 0.5s ease-out' }}>
          
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: '1 / 1', transition: 'all 0.5s ease-out' }} >
  
            <div className="App">
  
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1%' }}>
                <button onClick={handleBluetoothClick} style={{ display: 'flex', alignItems: 'center', fontSize: '24px', backgroundColor: 'lightblue', color: 'darkblue', marginRight: '10px', padding: '10px', border: 'none', borderRadius: '5px' }}>
                  <BluetoothIcon style={{ fontSize: '24px', color: 'darkblue', marginRight: '8px' }} />
                  Connect
                </button>
  
                <button onClick={handleDisconnect} style={{ display: 'flex', alignItems: 'center', fontSize: '24px', backgroundColor: 'lightcoral', color: 'darkred', padding: '10px', border: 'none', borderRadius: '5px' }}>
                  <BluetoothDisabledIcon style={{ fontSize: '24px', color: 'darkred', marginRight: '8px' }} />
                  Disconnect
                </button>
              </div>
  
              <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: (channelCharacteristic && batteryCharacteristic) ? 'green' : 'red', marginRight: '10px' }}/>
                  {channelCharacteristic && batteryCharacteristic ? <p>Device Connected</p> : <p>Device Disconnected</p>}
                </div>
              </div>
  
              <div>
                <b>flows</b>
                {flows.map((current, idx) => (
                    <input 
                        key={idx} 
                        type='number' 
                        style={{ width: "50px" }} 
                        value={current} 
                        min={0} 
                        max={100} 
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (isNaN(value)) {
                                console.error("Invalid input");
                            } else {
                                setFlow(idx, value);
                            }
                        }}
                    />
                ))}
                <button onClick={() => handleSetFlow(flows)}>send flows</button>
              </div>
  
              <div>
                <b>battery: {battery}%</b>
                <button style={{marginLeft:'1em'}} onClick={handleGetBattery}>get battery</button>
              </div>
            </div>
          </div>    
  
          <div id='container' style={ContainerStyle}>
  <div id='row1' style={RowStyle}>
    <Card 
        variant="outlined" 
        style={{...CardStyle, backgroundColor: '#ffb733'}}
        onClick={(e) => handleCardClick(e, { name: 'Orange', description: 'Juicy, Tangy, Energizing' })} 
    >
      <CardContent>
          <div id='nameOrange' style={{...TextStyle, fontSize: '1.75vw', marginBottom: '1vh'}} >
              Orange
          </div>
          <img src={Orange} 
              style={ImageStyle}
              alt="Orange"
              onMouseEnter={() => handleHover([50,50,50,50,50,0,0,0])}
              onMouseLeave={handleMouseLeave}></img>
          <div id='descriptionOrange' style={TextStyle} >Juicy, Tangy, Energizing</div>
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PlayCircleFilledIcon 
              name="play-button" 
              style={{ color: 'darkblue', fontSize: 40 }} 
              onClick={() => handlePlay([50,50,50,50,50,0,0,0])}  // Link the handlePlay function here
          />
      </CardActions>
    </Card>

    <Card 
        variant="outlined" 
        style={{...CardStyle, backgroundColor: '#a6784a'}}
        onClick={(e) => handleCardClick(e, { name: 'Barbeque Chicken', description: 'Savory, Tender, Smoky' })}
    >
        <CardContent>
            <div id='nameBBQChicken' style={{...TextStyle, fontSize: '1.75vw', marginBottom: '1vh'}} >
                BBQ Chicken
            </div>
            <img src={BBQChicken} 
                style={ImageStyle}
                alt="BBQ Chicken"
                onMouseEnter={() => handleHover([50,50,50,50,0,0,0,0])}
                onMouseLeave={handleMouseLeave}></img>
            <div id='descriptionBBQChicken' style={TextStyle} >Savory, Tender, Smoky</div>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PlayCircleFilledIcon 
                name="play-button" 
                style={{ color: 'darkblue', fontSize: 40 }} 
                onClick={() => handlePlay([50,50,50,50,0,0,0,0])}  // Link the handlePlay function here
            />
        </CardActions>
    </Card>
  </div>
</div>
          <Modal 
            isOpen={isModalOpen} 
            onClose={() => {
              console.log(`The video being closed is: ${videoSource}`);
              setModalOpen(false);
            }}
            videoRef={videoRef}
            videoSource={videoSource}
          />
        </div>
      </>
  );  
};

export default AdminMode;