import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminMode from './components/adminMode';
import Home from './components/Home';
import UserMode from './components/userMode';

const App = () => {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<AdminMode />} />
          <Route path="/user" element={<UserMode />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;