import React, { useRef, useState, ChangeEvent, useEffect, forwardRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import ReactDOMServer from 'react-dom/server';
import logo from './logo.svg';
import '../App.css';
import _ from "lodash";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Card, CardActions, CardContent, Button, Typography, Divider, TextField } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import Switch from '@material-ui/core/Switch';
import { any } from 'video.js/dist/types/utils/events';
import BBQChicken from './BBQ Chicken.jpg';
import Orange from './Orange.jpg';
import BBQ_Chicken from './BBQ_Chicken.mp4';
import TasteTrek_Citrus from './TasteTrek_Citrus_A_Global_Exploration.mp4';

type VideoJSProps = { 
  options?: any;
  onReady?: (player: any) => void;
};

type VideoJSButton = any & {
  prototype: {
    constructor: any;
    handleClick(): void;
  };
};

type Timestamp = {
    time: number;
    maxDuration: number;
    duration: number;
    numbers: number[];
    color: string;
};

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    videoRef: React.RefObject<HTMLVideoElement>;
    videoSource: string;
}

export const AdminMode = ({options, onReady}: VideoJSProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<any | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [currentFlow, setCurrentFlow] = React.useState<number[]>([0,0,0,0,0,0,0,0]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [videoAdded, setVideoAdded] = useState(false); // new state
  const [videoData, setVideoData] = useState<string | null>(null);
  const [videoName, setVideoName] = useState(''); // new state for video name
  const Forward10IconDataURL = `url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBQRC4gTWFkZSBieSB0aGVmb3JnZXNtaXRoOiBodHRwczovL2ljb25zLnRoZWZvcmdlc21pdGguY29tIC0tPgo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjZmZmZmZmIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMjMuOTMsNDEuNDFWMjNhLjA5LjA5LDAsMCwwLS4xNi0uMDdzLTIuNTgsMy42OS00LjE3LDQuNzgiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgogICAgPHJlY3QgeD0iMjkuMTkiIHk9IjIyLjUyIiB3aWR0aD0iMTEuNDEiIGhlaWdodD0iMTguODkiIHJ4PSI1LjciLz4KICAgIDxwb2x5bGluZSBwb2ludHM9IjU0LjQzIDE1LjQxIDUxLjgzIDI0LjA1IDQzLjE5IDIxLjQ0IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KICA8cGF0aCBkPSJNNTEuODYsMjMuOTRhMjEuOTEsMjEuOTEsMCwxLDAsLjkxLDEzLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+")`;
  const Replay10IconDataURL = `url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBQRC4gTWFkZSBieSB0aGVmb3JnZXNtaXRoOiBodHRwczovL2ljb25zLnRoZWZvcmdlc21pdGguY29tIC0tPgo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjZmZmZmZmIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMjMuOTMsNDEuNDFWMjNhLjA5LjA5LDAsMCwwLS4xNi0uMDdzLTIuNTgsMy42OS00LjE3LDQuNzgiICB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0LCAwKSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CiAgICA8cmVjdCB4PSIyOS4xOSIgeT0iMjIuNTIiIHdpZHRoPSIxMS40MSIgaGVpZ2h0PSIxOC44OSIgcng9IjUuNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCwgMCkiLz4KICAgIDxwb2x5bGluZSBwb2ludHM9IjU0LjQzIDE1LjQxIDUxLjgzIDI0LjA1IDQzLjE5IDIxLjQ0IiBzdHJva2UtbGluZWNhcD0icm91bmQiICB0cmFuc2Zvcm09InNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTYzLjUsIDApIi8+CiAgPHBhdGggZD0iTTUxLjg2LDIzLjk0YTIxLjkxLDIxLjkxLDAsMSwwLC45MSwxMy4yNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiAgdHJhbnNmb3JtPSJzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC02NCwgMCkiLz4KPC9zdmc+Cg==")`;
  const Button = videojs.getComponent('Button') as any;
  const [device, setDevice] = useState<BluetoothDevice | null>(null);
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [aromaDevice, setAromaDevice] = useState('Aroma Play'); // Set default value to 'Aroma Play'
  const [numProducts, setNumProducts] = useState(8); // Set default value to 8
  const ImageStyle: React.CSSProperties = { height: '25vh', width: '15vw', aspectRatio: '3 / 5', margin: 'auto', objectFit: 'cover' };
  const TextStyle: React.CSSProperties = {display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Jost', fontSize: '1.5vw'};
  const CardStyle: React.CSSProperties = {margin: '5vw', flex: '0 1 17vw'};
  const RowStyle: React.CSSProperties = {display: 'flex', flexWrap: 'wrap', justifyContent: 'center'};
  const ContainerStyle: React.CSSProperties = {padding: '1vw', width: '100vw', boxSizing: 'border-box'};
  const channelServiceUuid = "19B20000-E8F2-537E-4F6C-D104768A1214".toLowerCase();
  const batteryServiceUuid = "19B40000-E8F2-537E-4F6C-D104768A1214".toLowerCase();
  const channelCharacteristicUuid = "19b20001-e8f2-537e-4f6c-d104768a1214".toLowerCase();
  const batteryCharacteristicUuid = '19B40001-E8F2-537E-4F6C-D104768A1214'.toLowerCase();
  const [flows, setFlows] = useState<number[]>(Array(8).fill(0));
  const [targetFlows, setTargetFlows] = useState([0,0,0,0,0,0,0,0]);
  const [currentTime, setCurrentTime] = useState(0);
  const [battery, setBattery] = useState("unknown");
  const [channelCharacteristic, setChannelCharacteristic] = useState<BluetoothRemoteGATTCharacteristic>();
  const [batteryCharacteristic, setBatteryCharacteristic] = useState<BluetoothRemoteGATTCharacteristic>();
  const [connectedGattServer, setConnectedGattServer] = useState<BluetoothRemoteGATTServer>();
  const [targetFlow, setTargetFlow] = useState(0);
  const [currentTimestamp, setCurrentTimestamp] = useState<number | null>(null);
  const [activeTimestamp, setActiveTimestamp] = useState<Timestamp | null>(null);
  const [targetTimestamp, setTargetTimestamp] = useState<Timestamp | null>(null);
  let writeQueue: number[][] = [];
  let isWriting = false;
    let isProcessing = false; // This variable will ensure only one call to processQueue is active
    const [deviceDetails, setDeviceDetails] = useState<{
        gattServer?: BluetoothRemoteGATTServer;
        channelCharacteristic?: BluetoothRemoteGATTCharacteristic;
        batteryCharacteristic?: BluetoothRemoteGATTCharacteristic;
    }>({}); 

    const randomHexColor = () => {
        return "#" + Math.floor(Math.random()*16777215).toString(16);
    }

    const forwardButtonStyle: React.CSSProperties = {
        content: '""',  // Using React.CSSProperties, the content value should be wrapped in extra quotes for pseudo-elements
        display: 'inline-block', // To ensure the icon takes up space
        width: '24px', // Set the width of the icon
        height: '24px', // Set the height of the icon
        backgroundImage: Forward10IconDataURL,
        backgroundSize: 'contain',  // To ensure the SVG fits within the dimensions
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };

    const backwardButtonStyle: React.CSSProperties = {
        content: '""',  // Using React.CSSProperties, the content value should be wrapped in extra quotes for pseudo-elements
        display: 'inline-block', // To ensure the icon takes up space
        width: '24px', // Set the width of the icon
        height: '24px', // Set the height of the icon
        backgroundImage: Replay10IconDataURL,
        backgroundSize: 'contain',  // To ensure the SVG fits within the dimensions
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };

    useEffect(() => {
        // Add styles for the buttons dynamically to the document head
        const styleElement = document.createElement("style");
        styleElement.innerHTML = `
            .vjs-forward-button::before {
                content: "${forwardButtonStyle.content}";
                display: ${forwardButtonStyle.display};
                width: ${forwardButtonStyle.width};
                height: ${forwardButtonStyle.height};
                background-image: ${forwardButtonStyle.backgroundImage};
                background-size: ${forwardButtonStyle.backgroundSize};
                background-repeat: ${forwardButtonStyle.backgroundRepeat};
                background-position: ${forwardButtonStyle.backgroundPosition};
            }

            .vjs-backward-button::before {
                content: "${backwardButtonStyle.content}";
                display: ${backwardButtonStyle.display};
                width: ${backwardButtonStyle.width};
                height: ${backwardButtonStyle.height};
                background-image: ${backwardButtonStyle.backgroundImage};
                background-size: ${backwardButtonStyle.backgroundSize};
                background-repeat: ${backwardButtonStyle.backgroundRepeat};
                background-position: ${backwardButtonStyle.backgroundPosition};
            }
        `;

        document.head.appendChild(styleElement);

        // Cleanup: remove the style element when component unmounts
        return () => {
            document.head.removeChild(styleElement);
        };
    }, []);

    class ForwardButton extends (Button as any) {
        constructor(player: any, options: any) {
            super(player, options);
            this.addClass('vjs-forward-button');
            this.controlText("Forward 10 seconds");
        }

        handleClick() {
            const player = this.player();
            player.currentTime(player.currentTime() + 10);
        }
    }

    class BackwardButton extends (Button as any) {
        constructor(player: any, options: any) {
            super(player, options);
            this.addClass('vjs-backward-button');
            this.controlText("Backward 10 seconds");
        }

        handleClick(event: Event) {
            const player = this.player();
            player.currentTime(player.currentTime() - 10);
        }
    }

    videojs.registerComponent('ForwardButton', ForwardButton as any);
    videojs.registerComponent('BackwardButton', BackwardButton as any);

    const RETRY_DELAY = 50; // Time in milliseconds to wait before retrying
    const citrusTrekTimestamps = [{
        time: 20,  // converted 0:20 to seconds
        maxDuration: 224,  // converted 3:44 to seconds
        duration: 5,
        numbers: [80, 0, 0, 0, 0, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    }, 
    {
        time: 60,
        maxDuration: 184,
        duration: 7,
        numbers: [0, 80, 0, 0, 0, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    },
    {
        time: 88,
        maxDuration: 156,
        duration: 5,
        numbers: [0, 0, 80, 0, 0, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    },
    {
        time: 123,
        maxDuration: 121,
        duration: 5,
        numbers: [0, 0, 0, 80, 0, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    },
    {
        time: 235,
        maxDuration: 9,
        duration: 5,
        numbers: [0, 0, 0, 0, 80, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    }];
    const BBQChickenTimestamps = [{
        time: 15,  // converted 0:15 to seconds
        maxDuration: 79,  // converted 1:19 to seconds
        duration: 4,
        numbers: [80, 0, 0, 0, 0, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    }, 
    {
        time: 24,
        maxDuration: 70,
        duration: 7,
        numbers: [0, 80, 0, 0, 0, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    },
    {
        time: 50,
        maxDuration: 44,
        duration: 5,
        numbers: [0, 0, 80, 0, 0, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    },
    {
        time: 75,
        maxDuration: 19,
        duration: 7,
        numbers: [0, 0, 0, 80, 0, 0, 0, 0],
        color: randomHexColor()  // assign random color hex code here
    }];
    const [timestamps, setTimestamps] = useState(citrusTrekTimestamps); // or BBQChickenTimestamps

    const videoTimestamps: { [key: string]: typeof citrusTrekTimestamps | typeof BBQChickenTimestamps } = {
        [TasteTrek_Citrus]: citrusTrekTimestamps,
        [BBQ_Chicken]: BBQChickenTimestamps,
    };

console.log(timestamps)
useEffect(() => {
    if (playerRef.current) {
        
    const player = playerRef.current;

    // Add SkipForwardButton to the control bar
    player.getChild('controlBar').addChild('SkipForwardButton', {}, 6);  // 6 is just a suggested position, you can adjust

    // Add SkipBackwardButton to the control bar
    player.getChild('controlBar').addChild('SkipBackwardButton', {}, 5);  // 5 is just a suggested position, you can adjust
      const progressBar = playerRef.current.controlBar.progressControl.seekBar;
      const loadProgressBar = progressBar.el().querySelector(".vjs-load-progress");
      
      timestamps.forEach((ts) => {
        const div = document.createElement("div");
        div.style.background = ts.color;
        div.style.width = (ts.duration / playerRef.current.duration() * 100) + "%";
        div.style.left = (ts.time / playerRef.current.duration() * 100) + "%";
        loadProgressBar.appendChild(div);
      });
    }
  }, [timestamps]);
  

class Mutex {
    private _promiseChain: Promise<any> = Promise.resolve();

    lock(): Promise<() => void> {
        let resolveUnlock: (() => void) | null = null;
        let newLock = new Promise<void>(resolve => {
            resolveUnlock = resolve;
        });

        this._promiseChain = this._promiseChain.finally(() => newLock);
        return Promise.resolve(() => {
            if (resolveUnlock) resolveUnlock();
        });
    }
}

const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
  
const assignRandomColorsToTimestamps = (timestamps: any) => {
    return timestamps.map((timestamp: any) => {
        return {...timestamp, color: getRandomColor() };
    });
};

let retryDelay = 100;  // Initial delay for retries

const processQueue = async () => {
    if (writeQueue.length === 0 || isWriting || isProcessing) return;

    isProcessing = true;
    isWriting = true;

    // Ensure channelCharacteristic is defined before proceeding
    if (!channelCharacteristic) {
        console.error('channelCharacteristic is undefined');
        isProcessing = false;
        isWriting = false;
        return;
    }

    try {
        const dataToWrite = writeQueue.shift() as number[];
        await channelCharacteristic.writeValue(new Uint8Array(dataToWrite));
        retryDelay = 100;  // Reset retry delay on success
    } catch (error: any) {
        const dataToWrite = writeQueue.shift() as number[];
        if (error.message && error.message.includes("GATT operation already in progress")) {
            console.warn("GATT operation overlap detected. Retrying in", retryDelay, "ms...");
            writeQueue.unshift(dataToWrite);  // Push back the data to retry
            setTimeout(processQueue, retryDelay);
            retryDelay *= 2;  // Double the retry delay
            if (retryDelay > 2000) retryDelay = 2000;  // Cap the delay to a max value (e.g., 2 seconds)
            isProcessing = false;
            return;
        }
        console.error('Failed to write data:', error);
    } finally {
        isWriting = false;
        setTimeout(() => {
            processQueue();
            isProcessing = false;
        }, 50);   
    }
};
  
const handleBluetoothClick = async () => {
    let channelCharacteristicLocal;
    let batteryCharacteristicLocal;

    try {
        // Check for the existence of Web Bluetooth API
        if (!navigator.bluetooth) {
            console.error("Web Bluetooth API is not supported in this browser.");
            return;
        }

        const selectedDevice = await navigator.bluetooth.requestDevice({
            filters: [
                { namePrefix: "Nano" },
                { namePrefix: "Arduino" },
                { services: [channelServiceUuid, batteryServiceUuid] },
            ],
            optionalServices: [channelServiceUuid, batteryServiceUuid],
        });

        console.log(`Discovered a device!`);
        console.log(selectedDevice);

        if (!selectedDevice.gatt) {
            console.error("Gatt server is not available on the selected device.");
            return;
        }

        console.log('GATT Server:', selectedDevice.gatt);

        selectedDevice.ongattserverdisconnected = function (event) {
            console.log("Device disconnected", event);
            handleDisconnect(); // Handle disconnect gracefully
        };

        const server = await selectedDevice.gatt.connect();
        console.log(`Connected to GATT server`);
        console.log(server);

        const channelService = await server.getPrimaryService(channelServiceUuid);
        console.log('Channel Service:', channelService);
        channelCharacteristicLocal = await channelService.getCharacteristic(channelCharacteristicUuid);
        console.log('Channel Characteristic:', channelCharacteristicLocal);

        const batteryService = await server.getPrimaryService(batteryServiceUuid);
        console.log('Battery Service:', batteryService);
        batteryCharacteristicLocal = await batteryService.getCharacteristic(batteryCharacteristicUuid);
        console.log('Battery Characteristic:', batteryCharacteristicLocal);

        // Set the selected device and related properties
        setDevice(selectedDevice);
        setDeviceDetails({
            gattServer: server,
            channelCharacteristic: channelCharacteristicLocal,
            batteryCharacteristic: batteryCharacteristicLocal,
        });
        setConnectedGattServer(server);
        setDeviceConnected(true);
        setChannelCharacteristic(channelCharacteristicLocal);
        setBatteryCharacteristic(batteryCharacteristicLocal);

    } catch (error) {
        console.error(error);
    }

    console.log("Channel Characteristic:", channelCharacteristicLocal);
    console.log("Battery Characteristic:", batteryCharacteristicLocal);
};

const handleDisconnect = () => {
    console.log("handleDisconnect called");
    if (connectedGattServer && connectedGattServer.connected) {
      connectedGattServer.disconnect();
      console.log(`Disconnected from GATT server`);
      setConnectedGattServer(undefined);
      setChannelCharacteristic(undefined);
      setBatteryCharacteristic(undefined);
      setDeviceConnected(false);
    }
};

const exponentialBackoff = (
    max: number, 
    delay: number, 
    toTry: () => Promise<any>, 
    success: (result: any) => void, 
    fail: () => void
) => {
    toTry().then(result => success(result))
    .catch(_ => {
        if (max === 0) {
            return fail();
        }
        console.log('Retrying in ' + delay + 's... (' + max + ' tries left)');
        setTimeout(function() {
            exponentialBackoff(--max, delay * 2, toTry, success, fail);
        }, delay * 1000);
    });
}

const time = (text: string) => {
    console.log('[' + new Date().toJSON().substr(11, 8) + '] ' + text);
};

const handleSetFlow = async (flows: number[]): Promise<void> => {
    const flowsAsNumbers = flows.map((f: number) => {
        const parsed = f;
        return isNaN(parsed) ? 0 : parsed;
    });
    console.log(`Queueing flows for device: ${flowsAsNumbers}`);
    writeQueue.push(flowsAsNumbers);

    const unlock = await gattMutex.lock(); // Lock the mutex
    try {
        await processQueue(); // Once done, the queue is processed.
    } finally {
        unlock(); // Always unlock the mutex
    }
};
  
const updateFlows = (target: any) => {
    let currentFlows = [...flows]; // copy current flows to a new array
    const intervalId = setInterval(() => {
      // Check if all flows have reached the target
      if (currentFlows.every((flow) => flow >= target)) {
        clearInterval(intervalId); // If so, stop the interval
      } else {
        // If not, increment each flow that hasn't reached the target
        currentFlows = currentFlows.map((flow) => (flow < target ? flow + 1 : flow));
        setFlows(currentFlows); // Update state
      }
    }, 1000); // Update every second
};

useEffect(() => {
    handleSetFlow(flows);
}, [flows]);

useEffect(() => {
    const handleFullscreenChange = () => updateProgressCircles();
  
    if (!playerRef.current) return;
  
    // Listen to fullscreen changes and update progress circles
    playerRef.current.on('fullscreenchange', handleFullscreenChange);
  
    updateProgressCircles();
  
    return () => {
      // Clean up listener on unmount
      if (playerRef.current) {
        playerRef.current.off('fullscreenchange', handleFullscreenChange);
      }
    };
  }, [timestamps]);

const increaseFlow = (targetFlows: number[], time: number = 500) => {
    let current = 0;
    const intervalTime = 50; // 50 ms for smoother transition
    let steps = targetFlows.map((flow: number, index: number) => (flow - currentFlow[index]) / (time / intervalTime));
    let intervalId = setInterval(() => {
        if (current >= time) {
            clearInterval(intervalId);
            // Removed the decreaseFlow call here
        } else {
            setCurrentFlow(prevFlows => {
                const newFlows = prevFlows.map((prevFlow: number, index: number) => prevFlow + steps[index]);
                handleSetFlow(newFlows);
                return newFlows;
            });
            current += intervalTime;
        }
    }, intervalTime);
};
  
const decreaseFlow = (targetFlows: number[], time: number = 500) => {
    let current = 0;
    const intervalTime = 50; // 50 ms for smoother transition
    let steps = targetFlows.map((flow: number, index: number) => flow / (time / intervalTime));
    let intervalId = setInterval(() => {
      if (current >= time) {
        clearInterval(intervalId);
      } else {
        setCurrentFlow(prevFlows => {
            const newFlows = prevFlows.map((prevFlow: number, index: number) => prevFlow + steps[index]);
            handleSetFlow(newFlows);
            return newFlows;
        });
        current += intervalTime;
      }
    }, intervalTime);
  };
  
useEffect(() => {
    const matchingTimestamp = timestamps.find(t => currentTime >= t.time && currentTime <= t.time + t.duration);
    if (matchingTimestamp) {
        increaseFlow(matchingTimestamp.numbers, 500);
    } else if (!currentFlow.every(value => value === 0)) { // Added a condition to check if all values are already at 0
        decreaseFlow(currentFlow, 500);
    }
}, [currentTime]);
   
  
  const adjustFlow = (targetFlows: number[], time: number = 500) => {
    let current = 0;
    let steps = targetFlows.map((flow: number, index: number) => (flow - currentFlow[index]) / (time / 100));
    let intervalId = setInterval(() => {
      if (current >= time) {
        clearInterval(intervalId);
      } else {
        setCurrentFlow(prevFlows => {
          console.log(prevFlows); // check the previous state
          return prevFlows.map((prevFlow: number, index: number) => prevFlow + steps[index]);
        });
        console.log(currentFlow); // check the current state (might not reflect changes immediately due to async nature of set state)
        handleSetFlow(currentFlow.map((prevFlow: number, index: number) => prevFlow + steps[index]));
        current += 100;
      }
    }, 100);
  };

useEffect(() => {
    console.log(currentFlow);
}, [currentFlow]);

  const handleGetBattery = () => {
      batteryCharacteristic?.readValue().then(val => {
          console.log(`got val from battery: ${val.buffer}`)
          console.log(val)
          setBattery(`${val.getInt8(0)}`)
      })
  };

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, videoRef, videoSource }) => {
    const modalVideoRef = useRef<HTMLVideoElement>(null);
    const [flows, setFlows] = useState<number[]>(Array(8).fill(0));
    const [targetFlow, setTargetFlow] = useState(0);
    const [currentTimestamp, setCurrentTimestamp] = useState<number | null>(null);
    let resizeTimeout: NodeJS.Timeout;
  
    function createColoredBar(timestamp: { time: number, duration: number, color?: string }, videoDuration: number, progressBarWidth: number): HTMLElement {
        const bar = document.createElement('div');
        const startPercentage = timestamp.time / videoDuration;
        const durationPercentage = timestamp.duration / videoDuration;
    
        bar.style.position = 'absolute';
        bar.className = 'custom-bar';
        bar.style.height = '500%';
        bar.style.top = '-225%';
        bar.style.left = `${startPercentage * progressBarWidth}px`;
        bar.style.width = `${durationPercentage * progressBarWidth}px`;
        bar.style.backgroundColor = timestamp.color || 'red';
    
        return bar;
    }    
  
    const updateProgressCirclesForModal = () => {
        console.log("Updating circles...");
    
        if (!modalVideoRef.current) {
            console.log("modalVideoRef is null or undefined.");
            return;
        }
    
        const player = videojs(modalVideoRef.current);
        console.log("Player: ", player);
    
        const controlBar = player.getChild('controlBar');
        if (!controlBar) {
            console.log("Control bar not found.");
            return;
        }
    
        const progressControl = controlBar.getChild('progressControl');
        if (!progressControl) {
            console.log("Progress control not found.");
            return;
        }
    
        const progressBar = progressControl.el();
        const progressBarTrack = progressBar.querySelector('[role="slider"]');
        if (!progressBarTrack) {
            console.log("Progress bar track not found.");
            return;
        }
    
        progressBarTrack.querySelectorAll('.custom-bar').forEach(bar => bar.remove());
    
        console.log('videoTimestamps Object:', videoTimestamps);
        console.log('Current videoSource:', videoSource);
    
        const videosTimestamps = videoTimestamps[videoSource] || [];
        console.log("Videos Timestamps: ", videosTimestamps);
    
        if (videosTimestamps && videosTimestamps.length > 0) {
            const videoDuration = player.duration();
        
            clearTimeout(resizeTimeout);
        
            resizeTimeout = setTimeout(() => {
                const progressBarElement = progressBar;
                const progressBarWidth = (progressBarElement as HTMLElement)?.offsetWidth || 0;
        
                if (progressBarWidth === 0) {
                    console.error('Progress bar width is still 0 after delay.');
                    return;
                }
        
                for (let timestamp of videosTimestamps) {
                    const bar = createColoredBar(timestamp, videoDuration, progressBarWidth);
                    progressBarTrack.appendChild(bar);
                }
            }, 1000);
        }        
    };
  
    useEffect(() => {
        if (!modalVideoRef.current) return;
  
        const player = videojs(modalVideoRef.current);
        const handleMetadataLoaded = () => {
            console.log('Loaded metadata event fired!');
            updateProgressCirclesForModal();
        };
  
        player.on('loadedmetadata', handleMetadataLoaded);
  
        // Listen to the resize event
        window.addEventListener('resize', updateProgressCirclesForModal);
        
        // Trigger the update function a second after the modal is rendered
        // as an alternative to waiting for 'loadedmetadata' event
        const delayUpdateTimeout = setTimeout(updateProgressCirclesForModal, 1000);
        
        return () => {
            player.off('loadedmetadata', handleMetadataLoaded);
            player.dispose();
            window.removeEventListener('resize', updateProgressCirclesForModal);
            clearTimeout(delayUpdateTimeout);  // Clear the timeout to avoid memory leaks
        };
    }, [modalVideoRef, videoSource]);  // Added videoSource dependency to ensure updates when source changes  
  
    useEffect(() => {
        console.log('videoSource changed:', videoSource);
        updateProgressCirclesForModal();
    }, [videoSource]);
  
    const handleVideoTimeUpdate = useCallback((event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        const videoCurrentTime = event.currentTarget.currentTime;
        setCurrentTimestamp(videoCurrentTime);
    
        const videosTimestamps = videoTimestamps[videoSource];
        let currentFlows = Array(8).fill(0); // Default to zero flows
    
        const epsilon = 0.05;  // small margin of error
        let flowChanged = false;
    
        if (videosTimestamps) {
            for (let ts of videosTimestamps) {
                if (videoCurrentTime >= ts.time - epsilon && videoCurrentTime <= ts.time + ts.duration + epsilon) {
                    currentFlows = ts.numbers;
                    flowChanged = true;
                    break;
                }
            }
        }
    
        // Check if video time has exceeded the last timestamp's end
        const lastTimestamp = videosTimestamps[videosTimestamps.length - 1];
        if (videoCurrentTime > lastTimestamp.time + lastTimestamp.duration + epsilon) {
            console.log(`Outside of all timestamps at time: ${videoCurrentTime}`);
            currentFlows = Array(8).fill(0);
            flowChanged = true;
        }
    
        console.log(`FlowChanged: ${flowChanged}`);
        console.log(`Current Flows: ${JSON.stringify(currentFlows)}`);
        console.log(`Existing Flows: ${JSON.stringify(flows)}`);
    
        if (flowChanged && JSON.stringify(flows) !== JSON.stringify(currentFlows)) {
            console.log(`Flow changed at time: ${videoCurrentTime}`);
            setFlows(currentFlows); // Setting the flows state here
            handleSetFlow(currentFlows);
        }
    }, [videoTimestamps, videoSource, flows]);  
    
    if (!isOpen) {
        return null;
    }

    const modifiedOnClose = () => {
        setCurrentTimestamp(null);
        setFlows(Array(8).fill(0)); // Resetting the flows when the modal closes
        onClose();
    };
  
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                zIndex: 9999,
            }}
            onClick={modifiedOnClose}
        >
            <div
                style={{
                    background: '#fff',
                    height: '70%',
                    width: '70%',
                    position: 'relative',
                    boxSizing: 'border-box',
                    overflow: 'hidden',  // This ensures nothing spills out
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <video
                        style={{ width: '100%', height: '100%', position: 'absolute', top: '0', left: '0', objectFit: 'contain' }}
                        className='video-js vjs-big-play-centered'
                        src={videoSource}
                        controls
                        autoPlay
                        onTimeUpdate={handleVideoTimeUpdate}
                        ref={modalVideoRef}
                    />
                </div>
            </div>
        </div>
    );    
}  

useEffect(() => {
    const currentTs = timestamps.find(({ time, duration }) =>
      currentTime >= time && currentTime < time + duration
    );

    if (currentTs) {
      if (currentTs.time !== targetTimestamp?.time) {
        setTargetFlows(currentTs.numbers);
        setActiveTimestamp(currentTs);  // change here
      }
    } else {
      setTargetFlows([0, 0, 0, 0, 0, 0, 0, 0]);
      setActiveTimestamp(null);  // change here
    }
}, [currentTime]);

useEffect(() => {
    const currentTimestampData = timestamps.find(({time, duration, maxDuration}) =>
        currentTime >= time + duration && currentTime <= time + maxDuration
    );

    if(currentTimestampData){
        setTargetFlows([0,0,0,0,0,0,0,0]);
    }
}, [currentTime]);

useEffect(() => {
if (currentTimestamp === targetTimestamp) {
    updateFlows(targetFlow);
}
}, [currentTimestamp]);

// Custom Add TimeStamp button
class AddTimeStampButton extends ( Button as any as { new(...args: any[]): VideoJSButton; }) {
constructor(player:any, options:any) {
    super(player, options);
    this.on('click', () => {
    options.handleClick();
    });
    this.el().style.backgroundSize = 'calc(50%)';
    this.el().style.backgroundPosition = 'center';
    this.el().style.backgroundRepeat = 'no-repeat';
    this.el().style.color = 'white';
    this.el().title = 'Add Timestamp';
    this.el().style.cursor = 'pointer';
}

createEl() {
    return videojs.dom.createEl('button', {
    className: 'vjs-icon-plus vjs-control vjs-button',
    innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>`,
    });
}
}

const handleAddTimestampClick = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.currentTime();
  
      // Pause the video
      playerRef.current.pause();
        
      const newTimestamp: Timestamp = {
        time: currentTime,
        maxDuration: playerRef.current.duration() - currentTime,
        duration: 0,
        numbers: Array(8).fill(0),
        color: getRandomColor(),
      };
  
      // Add the new timestamp and sort the array
      setTimestamps((prevTimestamps) => {
        // Check if currentTime already exists in prevTimestamps
        if (prevTimestamps.some(timestamp => Math.abs(timestamp.time - currentTime) < 0.01)) {
          alert("Timestamp already exists for this time!");
          return prevTimestamps;
        }
  
        return [...prevTimestamps, newTimestamp].sort((a, b) => a.time - b.time);        
      });
    }
};

const updateProgressCircles = () => {
    if (!videoRef.current) return;

    const player = videojs(videoRef.current);
    const controlBar = player.getChild('controlBar');
    
    // Check if controlBar is not undefined
    if (!controlBar) return;

    const progressControl = controlBar.getChild('progressControl');

    if (!progressControl) return;

    const progressBar = progressControl.el();

    // Remove existing circles
    const existingCircles = progressBar.querySelectorAll('.vjs-timestamp-circle');
    existingCircles.forEach((circle: any) => circle.parentNode?.removeChild(circle));

    // If there are any timestamps, add new circles
    const videosTimestamps = videoTimestamps[videoSource];
    if (videosTimestamps && videosTimestamps.length > 0) {
        const videoDuration = player.duration();
        const progressBarElement = progressControl.el().querySelector('.vjs-load-progress');

        // Type assertion here
        const progressBarWidth = (progressBarElement as HTMLElement)?.offsetWidth || 0;

        // Create a colored circle for each timestamp
        videosTimestamps.forEach((timestamp) => {
            const circle = createColoredCircle(timestamp, videoDuration, progressBarWidth);
            progressBar.appendChild(circle);
        });
    }
};

const createColoredCircle = (timestamp: any, videoDuration: number, progressBarWidth: number): HTMLElement => {
    const positionPercentage = timestamp.time / videoDuration;
    const positionInPixels = positionPercentage * progressBarWidth;

    const circleElement = document.createElement('div');
    circleElement.className = 'custom-circle'; // or 'vjs-timestamp-circle' as per your setup
    circleElement.style.position = 'absolute';
    circleElement.style.left = `${positionInPixels}px`;
    circleElement.style.width = '10px';
    circleElement.style.height = '10px';
    circleElement.style.borderRadius = '50%';
    circleElement.style.backgroundColor = timestamp.color;
    circleElement.style.zIndex = '10'; // ensure it's above the other elements

    return circleElement;
}

useEffect(() => {
    console.log("Running player initialization useEffect");
    console.log("playerRef.current:", playerRef.current); 

    if (!playerRef.current && videoRef.current) {
        const videoElement = videoRef.current.querySelector('video');
        console.log("videoElement:", videoElement); 

        if (videoElement) { 
            const player = playerRef.current = videojs(videoElement, { 
                ...options, 
                fluid: true, 
                controls: true,
                controlBar: {
                // your control bar setup
                }
            }, () => {
                videojs.log('player is ready');
                onReady && onReady(player);
            });

            if(player){
                player.on('timeupdate', () => {
                setCurrentTime(player.currentTime());
                });
            }        

            // Add the button to the control bar
            player.getChild('controlBar')?.addChild('AddTimeStampButton', {
                text: 'Add Timestamp',
                handleClick: handleAddTimestampClick,
            }, 12);

            return () => {
                if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
                }
            };
        }
    }
    updateProgressCircles();
}, [options, onReady]);

let interval: any;  // Declare interval variable outside to clear it as needed
const gattMutex = new Mutex();

const handleHover = debounce(async (targetFlows: number[], isEntering: boolean = true) => {
    if (!channelCharacteristic) {
        console.warn("Hover action skipped because channelCharacteristic is not defined.");
        return;
    }

    if (interval) {
        clearInterval(interval);
    }

    let currentFlows = [...flows];

    if (!isEntering) {
        targetFlows = Array(flows.length).fill(0);
    }

    let numericFlows = currentFlows.map(flow => flow);
    const increments = targetFlows.map((target, idx) => (target - numericFlows[idx]) / 10);

    interval = setInterval(async () => {
        let allTargetsReached = true;
    
        for (let i = 0; i < numericFlows.length; i++) {
            numericFlows[i] += increments[i];
    
            if ((increments[i] > 0 && numericFlows[i] < targetFlows[i]) || 
                (increments[i] < 0 && numericFlows[i] > targetFlows[i])) {
                allTargetsReached = false;
            }
        }

        currentFlows = [...numericFlows];

        // Lock the mutex
        const unlock = await gattMutex.lock();
        try {
            await handleSetFlow(currentFlows); // This assumes handleSetFlow returns a promise. If it doesn't, remove the await.
        } finally {
            unlock();
        }

        if (allTargetsReached) {
            clearInterval(interval);
        }
    }, 50);

    setTimeout(() => {
        clearInterval(interval);
    }, 2500);
}, 200);

const handlePlay = (flows: number[]) => {
    handleHover(flows);
    setTimeout(() => {
        handleMouseLeave();
    }, 10000); // 10000 milliseconds = 10 seconds
}      

const handleMouseLeave = () => {
    const newFlows = [0,0,0,0,0,0,0,0];
    setFlows(newFlows);
    handleSetFlow(newFlows);
} 

const getFlowsForVideo = (videoSrc: string): number[] => {
    const timestamps = videoTimestamps[videoSrc] || [];
    return timestamps.map(timestamp => timestamp.numbers[0]);
};
  
const sendFlowsForBothVideos = () => {
    const BBQChickenFlows = getFlowsForVideo(BBQ_Chicken);
    const citrusTrekFlows = getFlowsForVideo(TasteTrek_Citrus);
    
    handleSetFlow([...BBQChickenFlows, ...citrusTrekFlows]);
};
  
const handleCardClick = (e: any, cardInfo: any) => {
    setModalOpen(true);

    let selectedTimestamps: any[] = [];
    let selectedVideoSource = "";

    switch (cardInfo.name) {
        case "Barbeque Chicken":
            selectedTimestamps = assignRandomColorsToTimestamps(BBQChickenTimestamps);
            selectedVideoSource = BBQ_Chicken;
            break;
        case "Orange":
            selectedTimestamps = assignRandomColorsToTimestamps(citrusTrekTimestamps);
            selectedVideoSource = TasteTrek_Citrus;
            break;
        default:
            console.log('Unexpected cardInfo.name:', cardInfo.name);
            break;
    }

    setVideoSource(selectedVideoSource);
    setTimestamps(selectedTimestamps);

    // Call the sendFlows function here
    sendFlowsForBothVideos();

    console.log('Card clicked:', cardInfo);
};

useEffect(() => {
    if (videoSource === TasteTrek_Citrus) {
        console.log("CitrusTrek was selected");
    } else if (videoSource === BBQ_Chicken) {
        console.log("BBQChicken was selected");
    }
    console.log('Timestamps updated:', timestamps);
}, [videoSource]);

useEffect(() => {
    if(currentTimestamp) {
        // Do any additional logic here, e.g., update the UI, flows, etc.
        console.log("Current timestamp changed:", currentTimestamp);
    }
}, [currentTimestamp]);
  
return (
    <>
        <div className="App" style={{ display: 'grid', gridTemplateRows: '1fr', gridTemplateColumns: '1fr', gap: '1rem', height: '100vh', alignItems: 'center', justifyContent: 'center', transition: 'all 0.5s ease-out' }}>
            
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: '1 / 1', transition: 'all 0.5s ease-out' }} >

            <div className="App">

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1%' }}>
                <button onClick={handleBluetoothClick} style={{ display: 'flex', alignItems: 'center', fontSize: '24px', backgroundColor: 'lightblue', color: 'darkblue', marginRight: '10px', padding: '10px', border: 'none', borderRadius: '5px' }}>
                    <BluetoothIcon style={{ fontSize: '24px', color: 'darkblue', marginRight: '8px' }} />
                    Connect
                </button>

                <button onClick={handleDisconnect} style={{ display: 'flex', alignItems: 'center', fontSize: '24px', backgroundColor: 'lightcoral', color: 'darkred', padding: '10px', border: 'none', borderRadius: '5px' }}>
                    <BluetoothDisabledIcon style={{ fontSize: '24px', color: 'darkred', marginRight: '8px' }} />
                    Disconnect
                </button>
                </div>

                <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: (channelCharacteristic && batteryCharacteristic) ? 'green' : 'red', marginRight: '10px' }}/>
                    {channelCharacteristic && batteryCharacteristic ? <p>Device Connected</p> : <p>Device Disconnected</p>}
                </div>
                </div>

                <div>
                <b>flows</b>
                {flows.map((current, idx) => (
                    <input 
                        key={idx} 
                        type='number' 
                        style={{ width: "50px" }} 
                        value={current} 
                        min={0} 
                        max={100} 
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (isNaN(value)) {
                                console.error("Invalid input");
                            } else {
                                if (!isNaN(value) && value >= 0 && value <= 9) {
                                    setFlows(prevFlows => {
                                        let updatedFlows = [...prevFlows];
                                        updatedFlows[idx] = value;
                                        return updatedFlows;
                                    });
                                }
                            }
                        }}
                    />
                ))}
                <button onClick={() => handleSetFlow(flows)}>send flows</button>
                </div>

                <div>
                <b>battery: {battery}%</b>
                <button style={{marginLeft:'1em'}} onClick={handleGetBattery}>get battery</button>
                </div>
            </div>
            </div>    

    <div id='container' style={ContainerStyle}>
        <div id='row1' style={RowStyle}>
            <Card 
                variant="outlined" 
                style={{...CardStyle, backgroundColor: '#ffb733'}}
                onClick={(e) => handleCardClick(e, { name: 'Orange', description: 'Juicy, Tangy, Energizing' })} 
            >
                <CardContent>
                    <div id='nameOrange' style={{...TextStyle, fontSize: '1.75vw', marginBottom: '1vh'}} >
                        Orange
                    </div>
                    <img src={Orange} 
                        style={ImageStyle}
                        alt="Orange"
                        onMouseEnter={() => handleHover([50,50,50,50,50,0,0,0])}
                        onMouseLeave={handleMouseLeave}></img>
                    <div id='descriptionOrange' style={TextStyle} >Juicy, Tangy, Energizing</div>
                </CardContent>
                <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PlayCircleFilledIcon 
                        name="play-button" 
                        style={{ color: 'darkblue', fontSize: 40 }} 
                        onClick={() => handlePlay([50,50,50,50,50,0,0,0])}  // Link the handlePlay function here
                    />
                </CardActions>
            </Card>

            <Card 
                variant="outlined" 
                style={{...CardStyle, backgroundColor: '#a6784a'}}
                onClick={(e) => handleCardClick(e, { name: 'Barbeque Chicken', description: 'Savory, Tender, Smoky' })}
            >
                <CardContent>
                    <div id='nameBBQChicken' style={{...TextStyle, fontSize: '1.75vw', marginBottom: '1vh'}} >
                        BBQ Chicken
                    </div>
                    <img src={BBQChicken} 
                        style={ImageStyle}
                        alt="BBQ Chicken"
                        onMouseEnter={() => handleHover([50,50,50,50,0,0,0,0])}
                        onMouseLeave={handleMouseLeave}></img>
                    <div id='descriptionBBQChicken' style={TextStyle} >Savory, Tender, Smoky</div>
                </CardContent>
                <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PlayCircleFilledIcon 
                        name="play-button" 
                        style={{ color: 'darkblue', fontSize: 40 }} 
                        onClick={() => handlePlay([50,50,50,50,0,0,0,0])}  // Link the handlePlay function here
                    />
                </CardActions>
            </Card>
        </div>
    </div>
            <Modal 
            isOpen={isModalOpen} 
            onClose={() => {
                console.log(`The video being closed is: ${videoSource}`);
                setModalOpen(false);
            }}
            videoRef={videoRef}
            videoSource={videoSource}
            />
        </div>
    </>
);  
};

export default AdminMode;